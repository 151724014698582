import React, { useMemo } from 'react'
import useSWR from 'swr'
import Selector from 'components/ui/Selector'
import DropdownLang from 'components/DropdownLang'
import MyPagination from 'components/ui/MyPagination'
import MyRefetchButton from 'components/ui/MyRefetchButton'
import { useForm } from './index'
import { LevelOptions } from 'config'

const orderOptions = [
  { code: 'updateTime', name: 'updateTime' },
  { code: 'createTime', name: 'createTime' },
  { code: 'dailyCount.reCreateCount', name: 'reCreateCount-daily' },
  { code: 'reCreateCount', name: 'reCreateCount-total' },
]
const DataTableToolbar = ({ mutate, nextAfter }) => {
  const { data } = useSWR(['/publishers'])
  const authorOptions = useMemo(() => {
    return (
      data?.map((author) => ({
        code: author.id,
        name: author.nick_name || author.username,
      })) || []
    )
  }, [data])
  const {
    level,
    setLevel,
    order,
    setOrder,
    author,
    setAuthor,
    afters,
    after,
    goAfter,
    lang,
    setLang,
  } = useForm()
  return (
    <>
      <div className="flex gap-4 mb-2">
        <DropdownLang value={lang} update={setLang} />
        <Selector
          id="order"
          datas={orderOptions}
          update={setOrder}
          value={order}
          labelName="order"
        />
        <Selector
          id="author"
          datas={authorOptions}
          update={setAuthor}
          value={author}
          labelName="author"
        />
        <Selector
          id="level"
          datas={LevelOptions}
          update={setLevel}
          value={level}
          labelName="level"
        />
        <MyRefetchButton onClick={() => mutate()} />
      </div>
      <MyPagination
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
    </>
  )
}

export default DataTableToolbar
