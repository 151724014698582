import React, { useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { PauseIcon, PlayIcon, EraserIcon } from '@radix-ui/react-icons'
import useSWR from 'swr'
import Page404 from '404'
import Loader from '@/components/ui/Loader'
import { Button } from '@/components/ui/button'
import Navbar from 'components/Navbar'
import CanvasCore from 'CanvasCore'
import { useEditorStore } from '../../CanvasCore/uiSlice'
import { cn } from '../../@/lib/utils'
import LayerPicker from '../../Editor/BottomPanel/LayerPanel/LayerPicker'
import { useUI } from '../../Editor'

const ReviewPanel = () => {
  const { tid } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get('avatarId')
  const drawRef = useRef(null)

  const { data, isLoading } = useSWR([`/avatars/${id}`])

  if (isLoading) return <Loader>Load data...</Loader>
  if (!data) return <Page404 />

  return (
    <div className="flex flex-col h-screen">
      <Navbar name="Neku workspace"></Navbar>
      <main className="w-full h-full gap-1 max-h-calc flex flex-col items-center">
        <div className="w-1/2 h-2/3">
          <CanvasCore ow={512} oh={512} drawRef={drawRef} />
        </div>
        <div className="w-1/2 flex flex-col gap-1 max-h-calc">
          <ProgressiveImageRenderer
            tid={tid}
            drawData={JSON.parse(data.drawData)}
          />
        </div>
      </main>
    </div>
  )
}

const ProgressiveImageRenderer = ({ tid, drawData }) => {
  const [images, setImages] = useState([])
  const [isPlaying, setIsPlaying] = useState(false)
  const currentIndexRef = useRef(0)
  const timerRef = useRef(null)
  const selectCpt = useEditorStore((state) => state.selectCpt)
  const resetCanvas = useEditorStore((state) => state.resetCanvas)
  const setTonings = useEditorStore((state) => state.setTonings)
  const toggleLayer = useUI((state) => state.toggleLayer)
  const lid = useUI((state) => state.lid)

  const cids = drawData.drawers.map((drawer, index) => {
    return {
      ...drawer,
      index,
    }
  })
  const tonings = drawData.tonings

  useEffect(() => {
    if (tonings) {
      setTonings(tid, tonings)
    }
  }, [tonings, tid, setTonings])

  const renderNextImage = () => {
    if (currentIndexRef.current < cids.length) {
      const cpt = {
        id: cids[currentIndexRef.current].cid,
        lid: cids[currentIndexRef.current].lid,
        crop: {
          x: cids[currentIndexRef.current].x,
          y: cids[currentIndexRef.current].y,
          w: cids[currentIndexRef.current].w,
          h: cids[currentIndexRef.current].h,
        },
      }
      const layer = {
        id: cids[currentIndexRef.current].lid,
        z: cids[currentIndexRef.current].z,
        toning: cids[currentIndexRef.current].t,
        max: 1,
      }
      setImages(cids.slice(0, currentIndexRef.current + 1))
      selectCpt(tid, layer, cpt)
      toggleLayer(layer.id)
      currentIndexRef.current++
    } else {
      setIsPlaying(false)
    }
  }

  const startRendering = () => {
    if (!isPlaying || currentIndexRef.current >= cids.length) {
      setIsPlaying(false)
      return
    }
    renderNextImage()
    timerRef.current = setTimeout(startRendering, 500)
  }

  const togglePlayPause = () => {
    if (isPlaying) {
      clearTimeout(timerRef.current)
    } else {
      startRendering()
    }
    setIsPlaying(!isPlaying)
  }

  const reset = () => {
    resetCanvas(tid)
    setImages([])
    currentIndexRef.current = 0
    if (isPlaying) {
      togglePlayPause()
    }
  }

  useEffect(() => {
    if (isPlaying) {
      startRendering()
    }

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [isPlaying])

  return (
    <div className="w-full h-full">
      <div className="flex items-center justify-between mb-5">
        <Button variant="outline" size="icon" onClick={togglePlayPause}>
          {isPlaying ? (
            <PauseIcon className="h-4 w-4" />
          ) : (
            <PlayIcon className="h-4 w-4" />
          )}
        </Button>
        <h2 className="mx-5">
          Render progress: {currentIndexRef.current}/{cids.length}
        </h2>
        <Button variant="outline" size="icon" onClick={reset}>
          <EraserIcon className="h-4 w-4" />
        </Button>
      </div>
      <LayerPicker />
      <div className="flex flex-wrap px-4">
        {images
          .filter((image) => image.lid === lid)
          .map(
            (image, index) =>
              image && (
                <div
                  key={`${image.cid}-${Date.now()}`}
                  className="flex flex-col justify-center items-center m-2"
                >
                  <div
                    className={cn(
                      'bg-white border-2 rounded-xl flex items-center justify-center group h-24 p-1',
                      index === currentIndexRef.current - 1 && 'border-primary',
                    )}
                  >
                    <img
                      src={`https://img.zthd.io/an1/ats/${image?.cid}`}
                      alt={`${image?.cid}`}
                      className="w-full h-full aspect-square object-contain rounded-xl"
                    />
                  </div>
                  <span className="mt-1">{image.index + 1}</span>
                </div>
              ),
          )}
      </div>
    </div>
  )
}

export default ReviewPanel
