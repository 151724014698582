import React, { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { PlusIcon } from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import MyPagination from 'components/ui/MyPagination'
import MyRefetchButton from 'components/ui/MyRefetchButton'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useForm } from './index'
import { Badge } from '@/components/ui/badge'

const DataTableToolbar = ({ mutate, nextAfter }) => {
  const afters = useForm((s) => s.afters)
  const after = useForm((s) => s.after)
  const goAfter = useForm((s) => s.goAfter)
  const updateTag = useForm((s) => s.setTag)
  const tag = useForm((s) => s.tag)

  return (
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center gap-2">
        <MyPagination
          nextAfter={nextAfter}
          after={after}
          goAfter={goAfter}
          afters={afters}
        />
        {tag && <Badge color="green">{tag}</Badge>}
        <Input
          id="tag"
          type="text"
          placeholder="search tag"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              updateTag(e.target.value.trim())
            }
          }}
        />
        <MyRefetchButton onClick={mutate} />
      </div>
      <DataTableRowCreate mutate={mutate} />
    </div>
  )
}

const DataTableRowCreate = ({ mutate }) => {
  const [isOpen, setIsOpen] = useState()
  const [tag, setTag] = useState()
  const [uploadFiles, setUploadFiles] = useState([])
  const { trigger, isMutating } = useSWRMutation('/meme-stickers', postFetcher)
  const selectFile = (fileList) => {
    const files = Array.from(fileList)
    if (!files) return
    setUploadFiles([])
    files.forEach((file) => {
      const img = new Image()
      img.onload = () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          setUploadFiles((items) => [...items, reader.result])
        }
        reader.readAsDataURL(file)
      }
      img.src = URL.createObjectURL(file)
    })
  }

  const onSubmit = async () => {
    uploadFiles.forEach((original) => {
      const data = { original, tag }
      trigger(data, {
        onSuccess: () => {
          mutate()
          setIsOpen(false)
        },
      })
    })
  }
  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          setUploadFiles([])
          setIsOpen(isOpen)
        }}
      >
        <DialogTrigger asChild>
          <Button className="h-8 lg:flex">
            <PlusIcon className="h-4 w-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              Create Meme Sticker
            </span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Meme Sticker</DialogTitle>
          </DialogHeader>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="tag">tag</Label>
            <Input
              id="tag"
              type="text"
              value={tag}
              onChange={(e) => setTag(e.target.value.trim())}
            />
          </div>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="icon">icon</Label>
            <Input
              id="icon"
              type="file"
              multiple
              accept=".jpg, .png, .jpeg, .webp, .gif, .bmp"
              onChange={(e) => selectFile(e.target.files)}
            />
          </div>
          <div className="flex flex-wrap">
            {uploadFiles.map((file, index) => (
              <img
                key={index}
                alt="..."
                className="aspect-square rounded-md border h-24 w-24 me-2"
                src={file}
              />
            ))}
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || uploadFiles.length === 0 || !tag}
              onClick={onSubmit}
            >
              Submit{isMutating ? '...' : null}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DataTableToolbar
