import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Form } from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import {
  MyFormCombobox,
  MyFormInput,
  MyFromTextarea,
} from 'components/ui/MyFormField'
import useSWR, { mutate } from 'swr'

import Loader from '@/components/ui/Loader'
import { putFetcher } from 'api/swr'
import useSWRMutation from 'swr/mutation'
import TemplateCover from './Cover'
import TemplatePreview from './Preview'

const infoFormSchema = z.object({
  coverField: z.enum(['cover', 'sketch'], {
    required_error: 'Please select a cover.',
  }),
  cover: z.string().optional(),
  sketch: z.string().optional(),
  initDataId: z.string().optional(),
  name: z
    .string()
    .min(2, {
      message: 'Username must be at least 2 characters.',
    })
    .max(30, {
      message: 'Username must not be longer than 30 characters.',
    }),
  intro: z.string().max(640).min(4),
  onlinePublisher: z.string(),
  preview: z.string().optional(),
  previewA: z.string().optional(),
})

const InfoPanel = () => {
  const { tid } = useParams()
  const { data: template } = useSWR([`/templates/${tid}`])
  const { data: users } = useSWR(['/publishers'])
  const [defaultValues] = useState({
    coverField: template?.coverField ?? 'cover',
    sketch: template?.sketch,
    cover: template?.cover,
    initDataId: template?.initDataId,
    name: template?.name ?? '',
    intro: template?.intro ?? '',
    preview: template?.preview,
    previewA: template?.previewA,
    onlinePublisher: template?.author,
  })

  const form = useForm({
    resolver: zodResolver(infoFormSchema),
    defaultValues,
  })

  const { trigger, isMutating, error } = useSWRMutation(
    `/templates/${template.id}`,
    putFetcher,
    {
      onSuccess: () => {
        mutate(['/templates', { tid }])
      },
    },
  )

  const onSubmit = (data) => {
    if (isMutating) return
    const user = users.find((user) => user.id === data.onlinePublisher)
    data.author = user.id
    data.onlinePublisher = user.nick_name || user.username
    trigger({ key: 'form', value: data })
  }

  if (!template) return null
  return (
    <div className="w-full space-y-4">
      <div className="rounded-md border p-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <TemplateCover form={form} template={template} />
            <TemplatePreview form={form} template={template} />
            <MyFormInput
              name="name"
              form={form}
              label="Name"
              placeholder={'template name'}
            />
            <MyFromTextarea
              name="intro"
              form={form}
              label="Description"
              placeholder={'template description'}
            />
            <MyFormCombobox
              name="onlinePublisher"
              form={form}
              items={
                users?.map((user) => ({
                  code: user.id,
                  name: user.nick_name || user.username,
                })) || []
              }
              label="onlinePublisher"
              placeholder={'template author name'}
            />
            {/* <MyFormSelect
              name="onlinePublisher"
              form={form}
              items={
                users?.map((user) => ({
                  code: user.id,
                  name: user.nick_name || user.username,
                })) || []
              }
              label="onlinePublisher"
              placeholder={'template author name'}
            /> */}
            {isMutating ? (
              <Loader>Updating...</Loader>
            ) : (
              <Button type="submit">Update</Button>
            )}
            {error && <div className="text-red-500">{error.message}</div>}
          </form>
        </Form>
      </div>
    </div>
  )
}

export default InfoPanel
