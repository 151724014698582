import React from 'react'
import Skeleton from '@/components/ui/skeleton'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { DataTableRowActions } from './data-table-row-action'
import { Badge } from '@/components/ui/badge'
import { Link } from 'react-router-dom'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: '',
    accessorKey: 'imgUrl',
    cell: ({ row }) => (
      <>
        {row.original.imgUrl ? (
          <img
            alt="..."
            className="aspect-square rounded-md object-cover h-24 w-24"
            src={row.original.imgUrl}
          />
        ) : (
          <Skeleton className="h-24 w-24 rounded-xl" />
        )}
      </>
    ),
  },
  {
    accessorKey: 'contentLang',
    header: 'ContentLang',
    cell: ({ row }) => (
      <>
        <Badge variant="outline">{row.original.contentLang}</Badge>
      </>
    ),
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: ({ row }) => (
      <>
        <Badge variant="outline">{row.original.type}</Badge>
      </>
    ),
  },
  {
    accessorKey: 'title',
    header: 'Title',
    cell: ({ row }) => (
      <>
        <div className="w-64">
          <p className="text-base">{row.original.title}</p>
          <p className="text-[grey] text-sm">{row.original.subTitle}</p>
        </div>
      </>
    ),
  },
  {
    accessorKey: 'jumpLink',
    header: 'JumpLink',
    cell: ({ row }) => (
      <>
        <Link target="_blank" to={row.original.jumpLink}>
          {row.original.jumpLink.slice(24)}
        </Link>
      </>
    ),
  },
  {
    header: 'CreateTime',
    accessorKey: 'createTime',
    cell: ({ row }) => (
      <DisplayUpdateTime updateTime={row.original.createTime} />
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => (
      <>
        <DataTableRowActions row={row} />
      </>
    ),
  },
]
