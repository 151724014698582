import React, { useMemo, useState } from 'react'
import { useAuthStore } from 'api/swr'
import useSWR from 'swr'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Button } from '@/components/ui/button'
import Title from 'components/ui/Title'
import { cn } from '@/lib/utils'

export const LayerCoverPicker = ({
  currCover,
  showMyIcon,
  children,
  onSubmit,
}) => {
  const userId = useAuthStore((state) => state.auth?.user?.id)
  const [selected, setSelected] = useState(currCover || null)

  const [keyword, setKeyword] = useState('')
  const [open, setOpen] = useState(false)
  const { data: globalIcons } = useSWR([
    '/layericons',
    { author: 'admin', limit: 700 },
  ])

  const { data: myIcons } = useSWR([
    showMyIcon ? '/layericons' : null,
    { author: userId },
  ])

  const quickIcons = useMemo(() => {
    if (keyword) {
      return globalIcons.filter((icon) => icon.name?.includes(keyword))
    }
    return globalIcons
  }, [keyword, globalIcons])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>Picker cover</DialogTitle>
          <div className="grid grid-cols-5">
            <Input
              className="col-span-3"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <div className="flex-1 overflow-y-auto max-h-[50vh]">
          <div className="grid grid-cols-5 gap-2 px-1">
            {showMyIcon && (
              <>
                <div className="col-span-5 flex flex-col">
                  <Separator className="my-4" />
                  <Title className="px-2">My icons:</Title>
                </div>
                {myIcons?.map((icon) => (
                  <CoverItem
                    key={icon.id}
                    icon={icon}
                    showName
                    selected={selected.id === icon.id}
                    onClick={() => setSelected(icon)}
                  />
                ))}
              </>
            )}

            <div className="col-span-5 flex flex-col">
              <Separator className="my-4" />
              <Title className="px-2">Quick section:</Title>
            </div>
            {quickIcons?.map((icon) => (
              <CoverItem
                key={icon.id}
                icon={icon}
                showName
                selected={selected.id === icon.id}
                onClick={() => setSelected(icon)}
              />
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              onSubmit(selected)
              setOpen(false)
            }}
          >
            Select
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const CoverItem = ({ icon, showName, selected, ...props }) => {
  return (
    <div className="flex flex-col items-center" {...props}>
      <div
        className={cn(
          'bg-white border-2 border-transparent rounded-xl w-full',
          'flex items-center justify-center group',
          'hover:border-primary',
          selected && 'border-primary',
        )}
      >
        <div className="w-full h-full relative">
          <img
            alt="..."
            className="w-full h-full aspect-square object-contain rounded-xl"
            src={`https://img.zthd.io/an1/acs/${icon.id}`}
          />
        </div>
      </div>
      {showName && <div className="min-h-8 truncate">{icon.name}</div>}
    </div>
  )
}
