import React, { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm as useHookForm } from 'react-hook-form'
import useSWRMutation from 'swr/mutation'
import { PlusIcon } from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import MyPagination from 'components/ui/MyPagination'
import MyRefetchButton from 'components/ui/MyRefetchButton'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import Loader from '@/components/ui/Loader'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useForm } from './index'
import { MyFormInput, MyFormSelect } from 'components/ui/MyFormField'
import { ContentLangs } from '../../config'

const DataTableToolbar = ({ mutate, nextAfter }) => {
  const afters = useForm((s) => s.afters)
  const after = useForm((s) => s.after)
  const goAfter = useForm((s) => s.goAfter)

  return (
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center gap-2">
        <MyPagination
          nextAfter={nextAfter}
          after={after}
          goAfter={goAfter}
          afters={afters}
        />
        <MyRefetchButton onClick={mutate} />
      </div>
      <DataTableRowCreate mutate={mutate} />
    </div>
  )
}

export const DataTableRowCreate = ({ mutate }) => {
  const [isOpen, setIsOpen] = useState()
  const { trigger, isMutating, error } = useSWRMutation(
    '/notifications',
    postFetcher,
  )
  const [defaultValues] = useState({
    title: '',
    subTitle: '',
    imgUrl: '',
    jumpLink: '',
    contentLang: 9,
  })

  const onSubmit = (data) => {
    if (isMutating) return
    const uploadData = { ...data }
    trigger(uploadData, {
      onSuccess: () => {
        setIsOpen(false)
        mutate()
      },
    })
  }

  const infoFormSchema = z.object({
    title: z.string(),
    subTitle: z.string(),
    imgUrl: z.string(),
    jumpLink: z.string(),
    contentLang: z.preprocess((val) => Number(val), z.number().min(3).max(46)),
  })
  const form = useHookForm({
    resolver: zodResolver(infoFormSchema),
    defaultValues,
  })
  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          setIsOpen(isOpen)
        }}
      >
        <DialogTrigger asChild>
          <Button className="h-8 lg:flex">
            <PlusIcon className="h-4 w-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              Create Notification
            </span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle> Create Notification</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <MyFormSelect
                name="contentLang"
                form={form}
                label="contentLang"
                items={ContentLangs}
              />
              <MyFormInput name="title" form={form} label="title" />
              <MyFormInput name="subTitle" form={form} label="subTitle" />
              <MyFormInput name="imgUrl" form={form} label="imgUrl" />
              <MyFormInput name="jumpLink" form={form} label="jumpLink" />

              {isMutating ? (
                <Loader>Updating...</Loader>
              ) : (
                <Button type="submit">Update</Button>
              )}
              {error && <div className="text-red-500">{error.message}</div>}
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DataTableToolbar
