import Page404 from '404'
import Loader from '@/components/ui/Loader'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import Nav from './Nav'
import CanvasPanel from './CanvasPanel'
import RightPanel from './RightPanel'

const ReviewPanel = () => {
  const { tid } = useParams()
  const { data, isLoading } = useSWR([`/dlc/drafts/${tid}`])

  if (isLoading) return <Loader>Load draft data...</Loader>
  if (!data) return <Page404 />

  return (
    <div className="flex flex-col h-screen">
      <Nav draft={data} />
      <main className="flex mt-1 gap-4 flex-grow  pb-2">
        <div className="w-1/2 flex flex-col gap-1 max-h-calc">
          <CanvasPanel draft={data} />
        </div>
        <div className="w-1/2 editor-right max-h-calc overflow-y-auto">
          <RightPanel draft={data} />
        </div>
      </main>
    </div>
  )
}

export default ReviewPanel
