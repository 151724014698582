import { Toaster } from '@/components/ui/toaster'
import React from 'react'

import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import Login from 'Auth/Login'
import Home from 'Home'

import PersonalPanel from 'PersonalPanel'
import PersonalAvatars from 'PersonalPanel/Avatars'
import PersonalLayout from 'PersonalPanel/Layout'

import ReviewDraft from 'AdminPanel/ReviewDraft'
import ReviewDlcDraft from 'AdminPanel/ReviewDraft/Dlc'
import ReviewAvatar from 'AdminPanel/ReviewDraft/Avatar'
import Editor from 'Editor'

import FrameAvatars from 'AdminPanel/Frames/Avatars'
import FrameLayout from 'AdminPanel/Frames/Layout'
import FrameDetail from 'AdminPanel/Frames/Detail'

import Avatars from 'AdminPanel/Avatars'
import AvatarDetail from 'AdminPanel/Avatars/Detail'
import AvatarRemixed from 'AdminPanel/Avatars/Remixed'
import WebImgs from 'AdminPanel/WebImgs'
import ValidAvatar from 'AdminPanel/Valid'

import UserAvatars from 'AdminPanel/Users/Avatars'
import UserLayout from 'AdminPanel/Users/Layout'

import Page404 from '404'

import AdminPublishers from 'AdminPanel/Publishers'
import PublisherDetail from 'AdminPanel/Publishers/Detail'

import RequireAdmin from 'AdminPanel/RequireAdmin'

import AdminTemplateLayout from 'AdminPanel/TemplateLayout'

import CommLayout from 'AdminPanel/CommLayout'
import TaglibLayout from 'AdminPanel/Taglibs/Layout'
import TaglibTemplates from 'AdminPanel/Onlines/Tag'
import TaglibDlcs from 'AdminPanel/DlcFrames/Tag'

import ReportPanel from 'AdminPanel/Frames/Report'

import DocsLayout from 'docs/layout'
import MarkdownRenderer from 'docs/markdown'

import { useAuthStore } from 'api/swr'

const App = () => {
  return (
    <div className="container p-1">
      <Routes>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        {/* <Route path="signup" element={<Signup />} /> */}

        <Route path="/" element={<RequireAuth />}>
          <Route path="personal" element={<PersonalLayout />}>
            <Route index element={<PersonalPanel />} />
            <Route
              path="templates/:tid/avatars"
              element={<PersonalAvatars />}
            />
          </Route>
          <Route path="editor/:tid" element={<Editor />} />
          <Route path="review/:tid" element={<ReviewDraft />} />
          <Route path="review/dlc/:tid" element={<ReviewDlcDraft />} />
          <Route path="review/avatar/:tid" element={<ReviewAvatar />} />
          <Route path="admin" element={<RequireAdmin />}>
            <Route path="" element={<AdminTemplateLayout />} />
            <Route path="avatars">
              <Route index element={<Avatars />} />
              <Route path=":aid" element={<AvatarDetail />}>
                <Route path="remixed" element={<AvatarRemixed />} />
              </Route>
            </Route>
            <Route path="comm" element={<CommLayout />} />
            <Route path="taglib/:tag" element={<TaglibLayout />}>
              <Route path="templates" element={<TaglibTemplates />} />
              <Route path="dlcs" element={<TaglibDlcs />} />
            </Route>
            <Route path="publishers" element={<AdminPublishers />} />
            <Route path="publishers/:id" element={<PublisherDetail />}>
              <Route path="templates" element={<AdminTemplateLayout />} />
            </Route>
            <Route path="webimgs" element={<WebImgs />} />
            <Route path="users" element={<UserLayout />}>
              <Route path=":id/avatars" element={<UserAvatars />} />
            </Route>
            <Route path="templates/:tid">
              <Route path="avatars" element={<FrameAvatars />} />
            </Route>
            <Route path="frames/:id" element={<FrameLayout />}>
              <Route path="detail" element={<FrameDetail />}></Route>
              <Route path="avatars" element={<FrameAvatars />} />
            </Route>
            <Route path="valid" element={<ValidAvatar />} />
            <Route path="report" element={<ReportPanel />} />
          </Route>
          <Route path="/docs" element={<DocsLayout />}>
            <Route path=":file" element={<MarkdownRenderer />} />
          </Route>
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Toaster />
    </div>
  )
}

const RequireAuth = () => {
  const isLoggedIn = useAuthStore((state) => state.auth?.user)
  const location = useLocation()
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  //TODO: ROLE LIMIT
  // console.log(location);

  return <Outlet />
}

export default App
